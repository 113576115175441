'use client';
import { useRef, useMemo, useEffect } from 'react';
import { PortableText } from '@portabletext/react';
import { components } from './PortableTextComponents';
import { m, useInView } from 'framer-motion';
import { fadeUp } from '@/assets/framer/animations';
import IndustryShowcase from './industry-personalization/IndustryShowcase';
import { AnimatePresence } from 'framer-motion';
import { useAtom } from 'jotai';
import { currentIndustryAtom } from '@/store/industryStore';
import { IndustryPersonalizationSection } from '@/types/types';
import IndustryBentoBox from './industry-personalization/IndustryBentoBox';
import IndustryCarousel from './industry-personalization/IndustryCarousel';
import IndustryStickyNav from './industry-personalization/IndustryStickyNav';
import {
  mixpanelIndustryImpression,
  mixpanelIndustryInView,
} from '@/lib/mixpanel/services/client';
import { shuffleArray } from '../utils/utils';
import IKTopDividerWave from '../shared/patterns/IKTopDividerWave';
import IKBottomDividerWave from '../shared/patterns/IKBottomDividerWave';
import IndustryNav from './industry-personalization/IndustryNav';

export default function IndustryPersonalization(
  props: IndustryPersonalizationSection
) {
  const [currentIndustry, setCurrentIndustry] = useAtom(currentIndustryAtom);

  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const description = props.data?.description;
  const industries = props.data?.industries;
  const featuredImages = props.data?.featuredImages;
  const mainButton = props.data?.mainButton;

  const headingRef = useRef(null);
  const section = useRef<HTMLElement | null>(null);
  const hasMounted = useRef(false);
  const previousIndustryTitleRef = useRef<string | null>(null); //track industry impression

  const isInView = useInView(headingRef, { amount: 0.4, once: true });
  const sectionInView = useInView(section, { amount: 0.2, once: false });

  useEffect(() => {
    if (sectionInView) {
      // When section comes into view, track "Industry in view" event
      mixpanelIndustryInView({ component_name: 'IndustryPersonalization' });
    }
    if (
      currentIndustry?.title &&
      currentIndustry.title !== previousIndustryTitleRef.current
    ) {
      mixpanelIndustryImpression({ title: currentIndustry.title });
      previousIndustryTitleRef.current = currentIndustry.title;
    }
  }, [currentIndustry, sectionInView]);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
    }

    // Always scroll when currentIndustry changes (including to/from null)
    setTimeout(() => {
      section.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 50);

    previousIndustryTitleRef.current = currentIndustry?.title || null;
  }, [currentIndustry]);

  // Randomize industries once on component mount
  const randomizedIndustries = useMemo(() => {
    return industries ? shuffleArray(industries) : [];
  }, [industries]);

  return (
    <section
      id={sectionId}
      ref={section}
      className={`relative z-40 scroll-m-16 bg-charcoal pb-10 pt-10 sm:pt-0`}
    >
      <IKTopDividerWave color='charcoal' />
      <div className='overflow-hidden pb-8'>
        <div className='container-padding container relative mx-auto'>
          <m.h2
            initial='hidden'
            animate={isInView ? 'visible' : 'hidden'}
            variants={fadeUp}
            ref={headingRef}
            className='heading-xl relative z-10 max-w-sm font-display font-medium tracking-tighter text-yellow-150 sm:max-w-4xl [&_strong]:text-yellow'
          >
            {heading && (
              <PortableText
                components={components}
                value={heading}
              ></PortableText>
            )}
          </m.h2>
          <m.p
            initial='hidden'
            animate={isInView ? 'visible' : 'hidden'}
            variants={fadeUp}
            ref={headingRef}
            className='heading-xs my-4 pr-4 text-white sm:pr-0 md:pr-5 lg:pr-10 xl:pr-0 [&_strong]:text-yellow'
          >
            {description && (
              <PortableText
                components={components}
                value={description}
              ></PortableText>
            )}
          </m.p>

          <div>
            {!currentIndustry ? (
              <div>
                {randomizedIndustries.length > 0 && (
                  // Bento box start
                  <div>
                    <div className='mt-12 hidden md:block'>
                      <IndustryBentoBox
                        industries={randomizedIndustries}
                        featuredImages={featuredImages}
                        setCurrentIndustry={setCurrentIndustry}
                        ctaHeading={props.data?.ctaHeading}
                        ctaCopy={props.data?.ctaCopy}
                        components={components}
                        mainButton={mainButton}
                      />
                    </div>
                    <div className='block md:hidden'>
                      <IndustryCarousel
                        industries={randomizedIndustries}
                        setCurrentIndustry={setCurrentIndustry}
                        ctaHeading={props.data?.ctaHeading}
                        ctaCopy={props.data?.ctaCopy}
                        components={components}
                        mainButton={mainButton}
                      />
                    </div>
                  </div>
                  // Bento box end
                )}
              </div>
            ) : (
              <div>
                {industries && !sectionInView && (
                  <IndustryNav industries={industries} />
                )}
                {industries && sectionInView && (
                  <IndustryStickyNav industries={industries} />
                )}
                <AnimatePresence mode='wait'>
                  {currentIndustry && (
                    <m.div
                      key={currentIndustry?.title}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className='mt-6'
                    >
                      <IndustryShowcase
                        title={currentIndustry?.title}
                        description={currentIndustry?.description}
                        merchantName={currentIndustry?.merchantName}
                        merchantDescription={
                          currentIndustry?.merchantDescription
                        }
                        subHeading={currentIndustry?.subHeading}
                        totalMerchants={currentIndustry?.totalMerchants}
                        totalTransactions={currentIndustry?.totalTransactions}
                        products={currentIndustry?.products}
                        featuredImage={currentIndustry?.featuredImage}
                        industryColor={currentIndustry?.industryColor}
                        industryIcon={currentIndustry?.industryIcon}
                        relatedArticle={currentIndustry?.relatedArticle}
                        profilePicture={currentIndustry?.profilePicture}
                        mainButton={mainButton}
                      />
                    </m.div>
                  )}
                </AnimatePresence>
              </div>
            )}
          </div>
        </div>
      </div>
      <IKBottomDividerWave color='charcoal' />
    </section>
  );
}
